import {initialize, addTranslationForLanguage} from 'react-localize-redux'

import {AuthTokenKey} from '../../infra/config/LocalStorageKeys'
import {GetLanguages} from '../../infra/requests/BaseRequests'
import {
  GetUserInfo,
  GetUserConfig
} from '../../infra/requests/UserRequests'
import {Languages} from '../../infra/translations/AvailableTranslations'
import isTokenExpired from '../../infra/utils/isTokenExpired'
import {setBackendVersion, updateInfo} from '../data/info/InfoActions'
import {userSave} from '../data/user/UserActions'

export default function start(history) {
  return async (dispatch) => {
    try {
      const {data: userConfigData} = await GetUserConfig(
        window.location.href
      )

      const {data: languagesDropData} = await GetLanguages('?version=true')

      const backendVersion = languagesDropData?.version

      dispatch(setBackendVersion(backendVersion))

      dispatch(
        initialize({
          languages: Languages,
          options: {
            renderToStaticMarkup: false,
            defaultLanguage:
              userConfigData?.defaultLanguage?.languageId || 2
          }
        })
      )
      Languages.forEach((lang) => {
        dispatch(addTranslationForLanguage(lang.file, lang.code))
      })

      const authToken = localStorage.getItem(AuthTokenKey)
      const authTokenIsExpired = authToken
        ? isTokenExpired(authToken)
        : true

      if (authTokenIsExpired) {
        history.push('/')
      } else if (authToken) {
        const {data: userData, success} = await GetUserInfo()

        if (success) {
          dispatch(userSave(userData))
        }
      }

      dispatch(
        updateInfo({
          ready: true
        })
      )
    } catch (error) {
      console.log(error)
    }
  }
}
